// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/root/project/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-you-js": () => import("/root/project/src/pages/about-you.js" /* webpackChunkName: "component---src-pages-about-you-js" */),
  "component---src-pages-dashboard-js": () => import("/root/project/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("/root/project/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interests-js": () => import("/root/project/src/pages/interests.js" /* webpackChunkName: "component---src-pages-interests-js" */),
  "component---src-pages-what-js": () => import("/root/project/src/pages/what.js" /* webpackChunkName: "component---src-pages-what-js" */)
}

